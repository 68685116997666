<template>
<div>
    <b-alert
      v-height-fade.appear
      :show="isPresent"
      dismissible
      class="mb-2"
      :variant="alert.type"
      @dismissed="alert.countdown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span>{{ alert.message }}</span>
      </div>
    </b-alert>
  <component :is="'b-card'">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" class="text-primary" />
      <h4 class="mb-0 ml-50 text-primary"> {{ $t('Kişisel Bilgiler') }}</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Ad -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Ad')" label-for="sAd">
              <validation-provider #default="{ errors }" rules="required" name="Adı">
                <b-form-input id="sAd" v-model="kullaniciData.sAd" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Soyad -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Soyad')" label-for="sSoyad">
              <validation-provider #default="{ errors }" rules="required" name="Soyadı">
                <b-form-input id="sSoyad" v-model="kullaniciData.sSoyad" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Telefon -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Telefon')" label-for="sTelefon">
              <validation-provider #default="{ errors }" rules="required" name="Telefon">
                <cleave id="sTelefon" v-model="kullaniciData.sTelefon" :state="errors.length > 0 ? false:null" placeholder="(999) 999-9999" class="form-control" :raw="false" :options="options.phone" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Kullanıcı Tipi -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Kullanıcı Tipi')" label-for="kullaniciTipi">
              <validation-provider #default="{ errors }" rules="required" name="Kullanıcı Tipi">
                <v-select
                  v-model="kullaniciData.kullaniciTipi"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="kullaniciTipiOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  input-id="kullaniciTipi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Durumlar -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="kullaniciData.isActive"
                  class="custom-control-primary">
                  {{ $t('Aktif Durumu') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="kullaniciData.isActiveSanalPos"
                  class="custom-control-primary">
                  {{ $t('Sanal Pos Durumu') }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon icon="SettingsIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">{{ $t('Hesap Bilgileri') }}</h4>
        </div>

        <!-- Form: User Info Form -->
        <b-row class="mt-1">
          <!-- Field: Kulanici Adi -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Kullanıcı Adı')" label-for="sKullaniciKodu">
              <validation-provider #default="{ errors }" rules="required|min:5|max:50" name="Kullanıcı Adı">
                <b-input-group>
                  <b-form-input id="sKullaniciKodu" v-model="kullaniciData.sKullaniciKodu" :state="errors.length > 0 ? false:null" />
                  <b-input-group-append>
                    <b-button :disabled="errors.length > 0" @click="checkAlan('sKullaniciKodu')" variant="outline-primary">
                       {{ $t('Kontrol') }}
                      </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: EPosta -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('E-Posta')" label-for="sEMail">
              <validation-provider #default="{ errors }" rules="required|email" name="E-Posta">
                <b-input-group>
                  <b-form-input id="sEMail" v-model="kullaniciData.sEMail" :state="errors.length > 0 ? false:null" />
                  <b-input-group-append>
                    <b-button :disabled="errors.length > 0" @click="checkAlan('sEMail')" variant="outline-primary">
                      {{ $t('Kontrol') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

           <!-- Field: Parola -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Parola')" label-for="sParola">
              <validation-provider #default="{ errors }" rules="required|password" name="Parola" vid="password">
                <b-input-group>
                <b-form-input id="sParola" v-model="kullaniciData.sParola" :state="errors.length > 0 ? false:null" />
                <b-input-group-append>
                  <b-button @click="generate" variant="outline-primary">
                    {{ $t('Üret') }}
                  </b-button>
                </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Musteri Info -->
        <div class="d-flex mt-2">
          <feather-icon icon="UserCheckIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">{{ $t('Müşteri Bilgisi') }}</h4>
        </div>
        <b-row class="mt-2">
          <b-col cols="12" md="6" lg="6">
             <musteri-selection :musteri-select.sync="kullaniciData.musteri_Id" />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <save-button 
              :onClickSaveAndClose="saveKullanici.bind(this, true)"
              :onClickSave="saveKullanici.bind(this, false)"
              
              />
          </b-col>
          <b-col>
            <b-button
              variant="outline-primary"
              class="mt-2 float-right"
              @click="sendEmail"
              >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
              />
            <span class="align-middle">{{ $t('Hatırlatma E-Postası Gönder') }}</span>
          </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'

// Alert fade
import { heightFade } from '@core/directives/animations'
// Save Button
import SaveButton from '@/components/SaveButton.vue'

// Alert
import AlertService from '@/common/alert.service'

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import ApiService from '@/common/api.service';
import KullaniciYonetimService from '@/common/custom-service/management/KullaniciYonetimService';
// Selection
import Cleave from 'vue-cleave-component'
import MusteriSelection from '../selection/MusteriSelection.vue';

export default {
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
    MusteriSelection,
    Cleave,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,
      email,
      kullaniciData: {
        id: 0,
        kullaniciTipi: null,
        sAd: null,
        sSoyad: null,
        sTelefon: null,
        isActive: false,
        isActiveSanalPos: false,
        sKullaniciKodu: null,
        sEMail: null,
        sParola: null,
        musteri_Id: null,
      },

      kullaniciTipiOptions: StaticOptions.kullaniciTipiOptions,
      isPresent: false,

      alert: {
        message: null,
        type: null,
        countdown: 5,
      },
      // Cleave options
      options: {    
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],    
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },
    }
  },

  methods: {
    fetchKullanici() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== -1) {
        this.$store.dispatch('fetchKullanici', { id: this.$router.currentRoute.params.id })
          .then(response => {
            if (response.statusCode === 200) {
              this.kullaniciData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      } else {
        this.kullaniciData.musteri_Id = this.$router.currentRoute.params.musteri_id
      }
    },

    saveKullanici(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveKullanici', this.kullaniciData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    generate: KullaniciYonetimService.generate,

    sendEmail() {
      AlertService.confirmSuccess(this, () => {
        KullaniciYonetimService.sendEmail(this.kullaniciData, this).then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this, response.message);
          } else {
            AlertService.error(this, response.message);
          }
        })
      }, 'Hatırlatma E-Postası işlemine onay veriyor musunuz?')
    },

    checkAlan(alanAdi) {
      let endPoint = 'manage/kullanicihesap/kullanici/kullanicikodu/check'
      const query = { id: this.kullaniciData.id };
      if (alanAdi === 'sEMail') {
        endPoint = 'manage/kullanicihesap/kullanici/email/check'
        query.sEMail = this.kullaniciData[alanAdi];
      } else {
        query.sKullaniciKodu = this.kullaniciData[alanAdi];
      }

      ApiService.post(endPoint, query, this).then(response => {
        if (response.result) {
          this.alert.type = 'warning'
          this.alert.message = response.message
          this.isPresent = true;
        } else {
          this.alert.type = 'success'
          this.alert.message = response.message
          this.isPresent = true;
        }
        setTimeout(() => {
            this.isPresent = false;
            this.alert.input = null
          }, 5000)
      })
    },

    countDownChanged(dismissCountDown) { 
      this.alert.countdown = dismissCountDown 
    },

    showAlert() { 
      this.alert.countdown = this.dismissSecs
    },
  },

  created() {
    this.fetchKullanici();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
