var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mb-2",attrs:{"show":_vm.isPresent,"dismissible":"","variant":_vm.alert.type},on:{"dismissed":function($event){_vm.alert.countdown = 0},"dismiss-count-down":_vm.countDownChanged}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.alert.message))])])]),_c('b-card',{tag:"component"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"UserIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('Kişisel Bilgiler')))])],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ad'),"label-for":"sAd"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Adı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sAd","state":errors.length > 0 ? false:null},model:{value:(_vm.kullaniciData.sAd),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "sAd", $$v)},expression:"kullaniciData.sAd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Soyad'),"label-for":"sSoyad"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Soyadı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sSoyad","state":errors.length > 0 ? false:null},model:{value:(_vm.kullaniciData.sSoyad),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "sSoyad", $$v)},expression:"kullaniciData.sSoyad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Telefon'),"label-for":"sTelefon"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Telefon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"sTelefon","state":errors.length > 0 ? false:null,"placeholder":"(999) 999-9999","raw":false,"options":_vm.options.phone},model:{value:(_vm.kullaniciData.sTelefon),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "sTelefon", $$v)},expression:"kullaniciData.sTelefon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Kullanıcı Tipi'),"label-for":"kullaniciTipi"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Kullanıcı Tipi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.kullaniciTipiOptions,"reduce":function (val) { return val.value; },"clearable":false,"state":errors.length > 0 ? false:null,"input-id":"kullaniciTipi"},model:{value:(_vm.kullaniciData.kullaniciTipi),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "kullaniciTipi", $$v)},expression:"kullaniciData.kullaniciTipi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.kullaniciData.isActive),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "isActive", $$v)},expression:"kullaniciData.isActive"}},[_vm._v(" "+_vm._s(_vm.$t('Aktif Durumu'))+" ")]),_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.kullaniciData.isActiveSanalPos),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "isActiveSanalPos", $$v)},expression:"kullaniciData.isActiveSanalPos"}},[_vm._v(" "+_vm._s(_vm.$t('Sanal Pos Durumu'))+" ")])],1)])],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"SettingsIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50 text-primary"},[_vm._v(_vm._s(_vm.$t('Hesap Bilgileri')))])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Kullanıcı Adı'),"label-for":"sKullaniciKodu"}},[_c('validation-provider',{attrs:{"rules":"required|min:5|max:50","name":"Kullanıcı Adı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sKullaniciKodu","state":errors.length > 0 ? false:null},model:{value:(_vm.kullaniciData.sKullaniciKodu),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "sKullaniciKodu", $$v)},expression:"kullaniciData.sKullaniciKodu"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":errors.length > 0,"variant":"outline-primary"},on:{"click":function($event){return _vm.checkAlan('sKullaniciKodu')}}},[_vm._v(" "+_vm._s(_vm.$t('Kontrol'))+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('E-Posta'),"label-for":"sEMail"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"E-Posta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sEMail","state":errors.length > 0 ? false:null},model:{value:(_vm.kullaniciData.sEMail),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "sEMail", $$v)},expression:"kullaniciData.sEMail"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":errors.length > 0,"variant":"outline-primary"},on:{"click":function($event){return _vm.checkAlan('sEMail')}}},[_vm._v(" "+_vm._s(_vm.$t('Kontrol'))+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Parola'),"label-for":"sParola"}},[_c('validation-provider',{attrs:{"rules":"required|password","name":"Parola","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sParola","state":errors.length > 0 ? false:null},model:{value:(_vm.kullaniciData.sParola),callback:function ($$v) {_vm.$set(_vm.kullaniciData, "sParola", $$v)},expression:"kullaniciData.sParola"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.generate}},[_vm._v(" "+_vm._s(_vm.$t('Üret'))+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"UserCheckIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50 text-primary"},[_vm._v(_vm._s(_vm.$t('Müşteri Bilgisi')))])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('musteri-selection',{attrs:{"musteri-select":_vm.kullaniciData.musteri_Id},on:{"update:musteriSelect":function($event){return _vm.$set(_vm.kullaniciData, "musteri_Id", $event)},"update:musteri-select":function($event){return _vm.$set(_vm.kullaniciData, "musteri_Id", $event)}}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.saveKullanici.bind(this, true),"onClickSave":_vm.saveKullanici.bind(this, false)}})],1),_c('b-col',[_c('b-button',{staticClass:"mt-2 float-right",attrs:{"variant":"outline-primary"},on:{"click":_vm.sendEmail}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SendIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Hatırlatma E-Postası Gönder')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }